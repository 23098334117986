@import url("https://fonts.googleapis.com/css2?family=Dosis&display=swap");

@tailwind base;
@tailwind components;

@layer components {
  .typo-marker {
    @apply bg-rose-400/30 underline decoration-4 decoration-red-600;
  }
  .seo-marker {
    @apply bg-emerald-400/30 underline decoration-emerald-600 decoration-4;
  }
  .autocomplete-marker {
    @apply text-gray-800/30;
  }

  .ql-editor {
    @apply text-2xl text-medium;
  }
}

// Additional styles
@import "additional-styles/utility-patterns.scss";
@import "additional-styles/range-slider.scss";
@import "additional-styles/toggle-switch.scss";
@import "additional-styles/flatpickr.scss";
@import "additional-styles/theme.scss";
@import "additional-styles/quilljs.scss";
@import "mapbox-gl/dist/mapbox-gl.css";
@import "@mapbox/mapbox-gl-geocoder/dist/mapbox-gl-geocoder.css";

// Swiper
@import "swiper/css";
@import "swiper/css/navigation";
@import "swiper/css/pagination";

// Swiper classes
.swiper-button-prev {
  background: white;
  opacity: 0.5;
  width: 50px;
  padding: 25px;
  border-radius: 100%;
  box-shadow: 2px 2px 10px black;
  margin-left: -5px;
}

.swiper-button-prev:hover {
  opacity: 1;
}

.swiper-button-prev::after {
  color: black;
  font-size: 20px;
  font-weight: bold;
}

.swiper-button-next {
  background: white;
  opacity: 0.5;
  width: 50px;
  padding: 25px;
  border-radius: 100%;
  box-shadow: 2px 2px 10px black;
  margin-right: -5px;
}

.swiper-button-next:hover {
  opacity: 1;
}

.swiper-button-next::after {
  color: black;
  font-size: 20px;
  font-weight: bold;
}

.mapboxgl-ctrl-geocoder--icon {
  display: none;
}

.mapboxgl-ctrl-geocoder--input {
  border-radius: 5px;
}

@tailwind utilities;
